/* eslint-disable @typescript-eslint/naming-convention */
import { fromEvent, Subscription } from 'rxjs';

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, OnDestroy } from '@angular/core';

import { IApiResponse } from '@bp/shared/models/core';
import { PaymentOptionType } from '@bp/shared/models/business';

import { SLIDE } from '@bp/frontend/animations';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { HostNotifierService } from '@bp/frontend/domains/checkout/services';

import { DepositProcessingService, AppService } from '@bp/checkout-frontend/providers';
import { CheckoutSession, IApiTransaction, IPaymentMethod, Transaction } from '@bp/checkout-frontend/models';

const walletResultEventName = '[bp]:wallet-result';

type WalletEvent = {
	event?: string;
	data: string;
};

type WalletResultEvent = MessageEvent<WalletEvent | undefined>;

@Component({
	selector: 'bp-general-payment-options-page',
	templateUrl: './general-payment-options-page.component.html',
	styleUrls: [ './general-payment-options-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE ],
})
export class GeneralPaymentOptionsPageComponent extends Destroyable implements OnDestroy {

	protected readonly _appService = inject(AppService);

	private readonly __depositProcessingService = inject(DepositProcessingService);

	private readonly __hostNotifier = inject(HostNotifierService);

	private readonly __cdr = inject(ChangeDetectorRef);

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected _paymentMethods = this._session.singlePaymentMethod
		? this._session.paymentMethods
			.filter(v => [
				this._session.singlePaymentMethod,
				this._session.singlePaymentMethod?.isApm ? PaymentOptionType.banks : null,
			].includes(v.type) && (this._session.singlePaymentProvider ? v.provider === this._session.singlePaymentProvider : true))
		: this._session.paymentMethods
			.sort(this.__moveWalletsToTheEnd);

	private _walletIframeListenerSubscription?: Subscription;

	constructor() {
		super();

		this.__listenToWalletIframeMessages();

	}

	ngOnDestroy(): void {
		this._walletIframeListenerSubscription?.unsubscribe();
	}

	protected _navigateToPaymentOption(method: IPaymentMethod): void {
		this.__hostNotifier.clickOnPaymentMethod({
			type: method.typeInSnakeCase,
			provider: method.provider,
		});

		this._appService.navigateToPaymentOption(method);

		this.__cdr.detectChanges();
	}

	private __moveWalletsToTheEnd(this: void, a: IPaymentMethod, b: IPaymentMethod): number {
		return Number(!!a.browser_checkout_url) - Number(!!b.browser_checkout_url);
	}

	private __listenToWalletIframeMessages(): void {
		this._walletIframeListenerSubscription = fromEvent<WalletResultEvent>(window, 'message')
			.pipe(takeUntilDestroyed(this))
			.subscribe(({ data }) => {
				if (!data?.event?.startsWith(walletResultEventName))
					return;

				const response = <IApiResponse<IApiTransaction | { transaction_id: string }>>JSON.parse(
					data.data,
				);

				const transaction = new Transaction(<IApiTransaction>response.result);

				if (transaction.status === 'cancelled')
					return;

				this.__depositProcessingService.handleAccordingTransaction(transaction, true);
			});
	}

}

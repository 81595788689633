/* eslint-disable complexity */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/naming-convention */

import { TranslateService } from '@ngx-translate/core';
import { camelCase, forOwn, isEmpty, isNil, omitBy, transform } from 'lodash-es';
import m from 'moment';
import { BehaviorSubject, firstValueFrom, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, filter, first, map, skip, startWith, takeUntil } from 'rxjs/operators';

import { ActivatedRoute, Router } from '@angular/router';
import { AsyncValidatorFn, FormBuilder, FormControl, FormGroup, FormGroupDirective } from '@angular/forms';
import { ChangeDetectionStrategy, Component, HostBinding, inject, OnInit, ViewChild } from '@angular/core';

import { FiatCurrency } from '@bp/shared/models/currencies';
import { hasMaskedChars, uuid } from '@bp/shared/utilities/core';
import { ResponseStatusCode } from '@bp/shared/models/core';
import { Validators } from '@bp/shared/features/validation/models';
import { Dictionary, ensureFormGroupConfig, Typify } from '@bp/shared/typings';
import { PaymentCardBrand, PaymentCardsUtils } from '@bp/shared/domains/payment-cards';
import { PaymentOptionType } from '@bp/shared/models/business';

import { FADE, FADE_IN, SLIDE } from '@bp/frontend/animations';
import { HostNotifierService } from '@bp/frontend/domains/checkout/services';
import { Destroyable, takeUntilDestroyed } from '@bp/frontend/models/common';
import { CheckoutValidators } from '@bp/frontend/domains/checkout/validation';
import { SavedPaymentCardToken } from '@bp/frontend/components/payment-card/models';
import { TelemetryService } from '@bp/frontend/services/telemetry';
import { BpError } from '@bp/frontend/models/core';
import { EnvironmentService } from '@bp/frontend/services/environment';
import { SavedPaymentCardTokensSelectComponent } from '@bp/frontend/components/payment-card/saved-payment-card-tokens-select';

import {
	CheckoutSession, CheckoutSessionValidatablePropertyNames, EmbeddedData, HAS_SELF_SUBMIT_FORM_KEY, IApiTransaction, IInstallmentOption, IPaymentMethod, IPersonalIdType, IRequestCardDeposit, Transaction, TransactionInfo
} from '@bp/checkout-frontend/models';
import { PaymentApiService, AppService, PaymentOptionInstancesManager } from '@bp/checkout-frontend/providers';

type DefaultInputField = CheckoutSessionValidatablePropertyNames & keyof IPaymentForm;

const additionalInputFields = <const>[
	'birthDate', 'gender', 'personalId', 'pin',
];

const PAYWITH_PAYMENT_FAILED_MSG = 'The payment failed, please, try again or try a different card.';
const cardBinIsNotAllowedErrorMessage = 'Card BIN is not allowed';

@Component({
	selector: 'bp-card-page',
	templateUrl: './card-page.component.html',
	styleUrls: [ './card-page.component.scss' ],
	changeDetection: ChangeDetectionStrategy.OnPush,
	animations: [ SLIDE, FADE, FADE_IN ],
})
export class CardPageComponent extends Destroyable implements OnInit {

	protected readonly _paymentOptionInstancesManager = inject(PaymentOptionInstancesManager);

	protected readonly _appService = inject(AppService);

	protected readonly _environment = inject(EnvironmentService);

	private readonly __formBuilder = inject(FormBuilder);

	private readonly __paymentsApiService = inject(PaymentApiService);

	private readonly __activatedRoute = inject(ActivatedRoute);

	private readonly __router = inject(Router);

	private readonly __translateService = inject(TranslateService);

	private readonly __hostNotifier = inject(HostNotifierService);

	private readonly __environment = inject(EnvironmentService);

	@HostBinding('class.checkout-lg')
	get isCheckoutLarge(): boolean {
		return this._shouldShowDefaultField('phone') && this._shouldShowDefaultField('stateCode');
	}

	protected get _session(): CheckoutSession {
		return this._appService.session!;
	}

	protected get _embeddedData(): EmbeddedData {
		return this._appService.embedded!;
	}

	protected _paymentMethod!: IPaymentMethod;

	protected _provider: string | 'none';

	protected get _isAstroPayProvider(): boolean {
		return this._provider === 'astro_pay';
	}

	protected get _isGateToPayProvider(): boolean {
		return this._provider === 'gatetopay';
	}

	protected get _isInstallments(): boolean {
		return !!this._paymentMethod.installments_order_summary;
	}

	protected readonly _isSubmitting$ = new BehaviorSubject(false);

	protected _form: FormGroup;

	protected _globalError?: string | null;

	protected _textFieldMaxLength = 255;

	protected _fiatCurrencies: FiatCurrency[] = [];

	protected _currency?: FiatCurrency;

	protected get _controls(): Typify<IPaymentForm, FormControl | undefined> {
		return <any> this._form.controls;
	}

	@ViewChild(FormGroupDirective)
	protected _formGroupDirective?: FormGroupDirective;

	@ViewChild(SavedPaymentCardTokensSelectComponent)
	protected _savedPaymentCardTokensSelectComponent?: SavedPaymentCardTokensSelectComponent;

	protected _startDate = m([ 1990, 1, 1 ]);

	protected _outOfAgeDate = m().subtract(18, 'years');

	protected _genders$: Observable<{ value: IRequestCardDeposit['gender']; display: string }[]> = this.__translateService.onLangChange
		.pipe(
			startWith(null),
			map(() => [
				{ value: 'female', display: <string> this.__translateService.instant('gender.female') },
				{ value: 'male', display: <string> this.__translateService.instant('gender.male') },
			]),
		);

	protected _secureLogosPrefix = this._session.theme.isDark || this._session.isVantageFX || this._session.isSecurePharma ? '-white' : '';

	protected _selectedInstallmentOption?: IInstallmentOption;

	protected get _isPspsFingerprintsCollectionRequired(): boolean {
		return !!this._paymentMethod.ddc_htmls;
	}

	protected _pspsFingerprints?: Dictionary<string>;

	protected _allFingerprintsCollected$ = new BehaviorSubject<boolean>(false);

	protected _pspBadge$ = new BehaviorSubject<string | null>(null);

	protected _paymentCardBrand: PaymentCardBrand | null = null;

	protected _paymentCardTokens$ = new BehaviorSubject<SavedPaymentCardToken[] | null>(null);

	protected _showSavedPaymentCardTokensSelect$ = this._paymentCardTokens$.pipe(
		map(paymentCardTokens => !!paymentCardTokens?.length && this._session.allowCreateCreditCardToken && !this._paymentMethod.process_as_credit_card),
	);

	protected get _cannotSubmit(): boolean {
		return (!this._session.validateInputsOnFocusOut && this._form.invalid) || this._form.pending;
	}

	private readonly __inputFields: DefaultInputField[] = [
		'address', 'city', 'zipCode', 'phone', 'email', 'firstName', 'lastName', 'cardHolderName',
	];

	private __wasOnceInvalidMap: Record<string, boolean> = {};

	private __paymentRequestBody: IRequestCardDeposit | null = null;

	private __nullifyCardTokenDetailsOnInputSubscription = Subscription.EMPTY;

	protected readonly _optionalFields = new Set<keyof IPaymentForm>();

	// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
	private get __notificationContext() {
		return {
			type: this._paymentMethod.typeInSnakeCase,
			provider: this._paymentMethod.provider,
		};
	}

	constructor() {
		super();

		this._isSubmitting$
			.pipe(
				skip(1),
				takeUntilDestroyed(this),
			)
			.subscribe(isSubmitting => void this.__hostNotifier.processingPayment({
				...this.__notificationContext,
				value: isSubmitting,
			}));

		// eslint-disable-next-line prefer-const
		let { provider, paymentMethodType } = this.__activatedRoute.snapshot.params;

		paymentMethodType = PaymentOptionType.parse(paymentMethodType) ?? PaymentOptionType.creditCard;

		const paymentProviderMethod = this._session.paymentMethods
			.find(paymentMethod => paymentMethod.type === paymentMethodType && paymentMethod.provider === provider);

		this._paymentMethod = paymentProviderMethod ?? this._session.paymentMethods.find(paymentMethod => paymentMethod.type === paymentMethodType)!;

		if (!isEmpty(this._paymentMethod.optional_fields)) {
			this._optionalFields = new Set(
				(<(keyof IRequestCardDeposit)[]> < unknown > this._paymentMethod.optional_fields).map(requestPropertyName => this.__mapRequestPropertyNameToControlName(requestPropertyName)),
			);
		}

		this.__hostNotifier.paymentMethodOpen(this.__notificationContext);

		this._provider = this._session.paymentProvider || provider || this._paymentMethod.provider;

		if (this._session.allowCreateCreditCardToken) {
			this._appService
				.getPaymentCardTokens(this._provider)
				.pipe(takeUntilDestroyed(this))
				.subscribe(paymentCardTokens => void this._paymentCardTokens$.next(paymentCardTokens));
		}

		this._fiatCurrencies = this._paymentMethod.currencies!.map(v => new FiatCurrency(v));

		if (this._appService.transaction)
			this.__whenDeclinedDueToInvalidCreditCardShowError(this._appService.transaction);

		this._selectedInstallmentOption = this._paymentMethod.installments_order_summary?.installment_options?.[0];

		if (this._session.country.states)
			this.__inputFields.push('stateCode');

		this._form = this.__formBuilder.group(
			ensureFormGroupConfig<IPaymentForm>({
				...(this._paymentMethod.fields
					? transform(
						this._paymentMethod.fields,
						(config, fieldMetadata) => (config[fieldMetadata.name] = [
							null,
							{ validators: fieldMetadata.required ? Validators.required : null },
						]),
					)
					: {}),

				...transform(
					this.__inputFields.filter(v => !this.__isHiddenField(v)),
					(config, key) => (config[key] = [
						this._session[key].value,
						{ validators: this._session[key].validator },
					]),
				),

				...(this._session.amountLock || this._paymentMethod.hide_amount ? {} : { amount: [ '' ]}),

				...(this._session.country.states
					? {
						state: [
							this._session.stateCode.valid ? this._session.country.states.find(v => this._session.stateCode.value === v.code)!.name : '',
							{
								validators: [
									Validators.required,
									CheckoutValidators.stateByName(this._session.country),
								],
							},
						],
					}
					: {}
				),

				...(this._paymentMethod.installments_order_summary?.installment_options
					? {
						installmentOptionId: [
							this._selectedInstallmentOption!.id,
							{ validators: Validators.required },
						],
					}
					: {}
				),

				countryDialCode: [
					this._session.phoneCountryDialCode,
					{ validators: Validators.required },
				],

				cardNumber: [
					this._embeddedData.creditCard?.number.toString() ?? '',
					{
						validators: Validators.required,
						asyncValidators: this._session.enableFilterByCardBrand && this._paymentMethod.type.isCreditCard
							? this.__backendCardBinValidator()
							: null,
					},
				],

				cardExpiryDate: [
					this._embeddedData.creditCard && `${ this._embeddedData.creditCard.expireMonth }/${ this._embeddedData.creditCard.expireYear }` || '',
					{ validators: Validators.required },
				],

				cardCvv: [
					this._embeddedData.creditCard?.cvv.toString() ?? '',
					{
						validators: this._optionalFields.has('cardCvv')
							? Validators.noop
							: Validators.required,
					},
				],

				saveCard: [ this._session.tickSaveCreditCardCheckboxByDefault ],

				cardToken: [ null ],

				...transform(
					additionalInputFields.filter(v => this._isAdditional(v) && !this.__isHiddenField(v)),
					(config, key) => (config[key] = [
						key === 'birthDate'
							? this._session.birthDate ?? null
							: (key === 'personalId' ? this._session.personalId ?? null : null),
						{ validators: Validators.required },
					]),
				),

				...(this._isAdditional('personalId') && !!this._paymentMethod.personal_id_types
					? {
						personalIdType: [
							this._session.personalIdType ?? this._paymentMethod.personal_id_types[0],
							{ validators: Validators.required },
						],
					}
					: {}
				),
			}),
			{
				updateOn: this._session.validateInputsOnFocusOut ? 'blur' : 'change',
			},
		);

		this.__reflectEmbedValidationStateToControls();

		if (this._session.firstName.valid && this._session.lastName.invalid)
			this._controls.cardHolderName!.markAsDirty();

		if (this._paymentMethod.has_badge) {
			this.__paymentsApiService
				.getPspBadge(this._provider) // none handled by the backend
				.pipe(takeUntilDestroyed(this))
				.subscribe({
					next: pspBadge => void this._pspBadge$.next(pspBadge),
					error: () => void TelemetryService.captureError(`Failed to get psp ${ this._provider } badge`),
				});
		}

		if (this._paymentMethod.psp_badge)
			this._pspBadge$.next(this._paymentMethod.psp_badge);

		if (this._session.isPayWithCheckout)
			this._currency = this._session.currency;

		this.__whenFormDirtyNotifyHost();

		this.__updateTelemetryUserIdOnControlChanges();
	}

	ngOnInit(): void {
		this._controls.state
			?.valueChanges
			.pipe(
				filter(() => this._controls.state!.valid),
				map(stateName => this._session.country.states!.find(it => it.name === stateName)!),
				takeUntilDestroyed(this),
			)
			.subscribe(state => void this._controls.stateCode!.setValue(state.code));

		if (this._controls.installmentOptionId) {
			this._controls.installmentOptionId.valueChanges
				.pipe(takeUntilDestroyed(this))
				.subscribe(id => (this._selectedInstallmentOption = this._paymentMethod.installments_order_summary!.installment_options!.find(option => option.id === id)));
		}
	}

	private __updateTelemetryUserIdOnControlChanges(): void {
		this._controls.email?.valueChanges
			.pipe(
				filter(() => this._controls.email!.valid),
				takeUntilDestroyed(this),
			)
			.subscribe((email: string) => void this._appService.telemetryIdentifyUser(email));
	}

	private __whenFormDirtyNotifyHost(): void {
		this._form.valueChanges
			.pipe(
				first(() => this._form.dirty),
				takeUntilDestroyed(this),
			)
			.subscribe(() => void this.__hostNotifier.paymentMethodFormDirty(this.__notificationContext));
	}

	private __reflectEmbedValidationStateToControls(): void {
		this.__inputFields
			.filter(inputField => !this.__isHiddenField(inputField) && this._session[inputField].value && this._session[inputField].invalid)
			.forEach(inputField => void this._controls[inputField]!.markAsDirty());
	}

	protected _originalOrderKeyValueComparator = () => 0;

	protected _markAsDirtyAllControls(): void {
		forOwn(
			this._controls,
			control => {
				if (control!.pristine) {
					void control!.markAsDirty();

					void control!.updateValueAndValidity(); // invoke changes
				}
			},
		);
	}

	protected _revalidatePaymentCardNumber(): void {
		this._controls.cardNumber?.updateValueAndValidity();
	}

	private __wasOnceInvalid(key: DefaultInputField): boolean {
		if (this.__wasOnceInvalidMap[key])
			return true;

		if (this._session[key].invalid) {
			this.__wasOnceInvalidMap[key] = true;

			return true;
		}

		if (this._controls[key]?.invalid) {
			this.__wasOnceInvalidMap[key] = true;

			return true;
		}

		return false;
	}

	protected _shouldShowDefaultField(key: DefaultInputField): boolean {
		if (this.__isHiddenField(key))
			return false;

		if (key === 'cardHolderName') {
			return this._session.cardHolderName.value && this._session.hideCardHolderNameWhenFullNameIsAvailable
				? this.__wasOnceInvalid(key)
				: true;
		}

		if (this._session.alwaysVisibleInputsForProviders[key]?.includes(
			this._provider === 'none' ? 'credit_card' : this._provider,
		))
			return true;

		return this.__wasOnceInvalid(key);
	}

	protected _getAmount(): number {
		return this._session.amountLock
			? this._paymentOptionInstancesManager.activeInstance?.amount ?? this._paymentMethod.displayAmount
			: Number(this._controls.amount!.value);
	}

	protected _patchPaymentCardInputsOnPaymentCardTokenSelection(
		{ number, token, expirationDate, holder }: SavedPaymentCardToken,
	): void {
		this.__nullifyCardTokenDetailsOnInputSubscription.unsubscribe();

		this._form.patchValue(<Partial<IPaymentForm>>{
			cardNumber: number,
			cardExpiryDate: expirationDate,
			cardHolderName: holder,
			cardToken: token,
		});

		// we have a logic with secret inputs, so on each change of CC number we should mark it as pristine
		this._controls.cardNumber!.markAsPristine();

		this._controls.cardExpiryDate!.markAsTouched();

		// our controls used microtask queue to update value, so we should subscribe after everything got updated
		setTimeout(() => void this.__onCardDetailsChangeWhileCardTokenChosenResetCardToken());
	}

	private __onCardDetailsChangeWhileCardTokenChosenResetCardToken(): void {
		const cardTokenCardNumber = this._controls.cardNumber!.value;

		this.__nullifyCardTokenDetailsOnInputSubscription = this._controls.cardNumber!.valueChanges.pipe(
			filter(value => value !== cardTokenCardNumber),
			takeUntilDestroyed(this),
		)
			.subscribe(() => {
				this.__nullifyCardTokenDetailsOnInputSubscription.unsubscribe();

				void this._form.patchValue(<Typify<IPaymentForm, null>>{
					cardToken: null,
					cardCvv: null,
					cardHolderName: null,
					cardExpiryDate: null,
				});
			});
	}

	// #region submitting
	protected async _submit(): Promise<void> {
		if (this._isSubmitting$.value)
			return;

		TelemetryService.log('Payment form submitted');

		if (this._form.invalid) {
			this._markAsDirtyAllControls();

			TelemetryService.log('Payment form is invalid');

			return;
		}

		this._globalError = null;

		this._isSubmitting$.next(true);

		if (this._isPspsFingerprintsCollectionRequired && !this._allFingerprintsCollected$.value) {
			TelemetryService.log('Waiting for all fingerprints to be collected');

			await firstValueFrom(
				timer(950) // cannot be bigger safari won't open a new tab without confirm popup
					.pipe(takeUntil(
						this._allFingerprintsCollected$.pipe(filter(isCollected => isCollected)),
					)),
				{ defaultValue: null },
			);

			TelemetryService.log('All fingerprints collected');
		}

		this._appService.returnUrl = this.__router.url;

		this.__paymentRequestBody = this.__buildPaymentRequestBody();

		this._appService.storePaymentRequestBody(this.__paymentRequestBody);

		if (this._appService.isEmbedded && this._paymentMethod.open_in_new_window) {
			this._appService.paymentProcessingPageUrl = await this._appService.buildUrlWithEmbedParamsAndDepositRequest(
				'deposit-processing',
				this.__paymentRequestBody,
			);

			TelemetryService.log(
				'Opened a new tab',
				this.__environment.isNotProduction
					? this._appService.paymentProcessingPageUrl
					: null,
			);

			this._appService.openTab = window.open(
				this._appService.paymentProcessingPageUrl,
				this._appService.getTabWindowName(),
			);

			this._appService.hasDoneDeposit();

			this._isSubmitting$.next(false);

			this._appService.navigate([ '/status/card-proceed-on-open-tab' ]);
		} else
			void this.__makePayment();
	}

	private async __makePayment(): Promise<void> {
		this._appService.alertBeforeUnload();

		try {
			TelemetryService.log('Making payment');

			const result = await firstValueFrom(
				this.__paymentsApiService.depositCreditCard(this.__paymentRequestBody!),
			);

			this.__onPaymentResponse(result);
		} catch (error: unknown) {
			TelemetryService.log('Payment failed', error);

			if (error instanceof BpError)
				this.__onPaymentError(error);
			else
				throw error;
		}
	}

	private async __checkPaymentStatus(transaction: Transaction): Promise<void> {
		try {
			const result = await firstValueFrom(
				this.__paymentsApiService.checkPspTransactionStatus(transaction.id),
			);

			this.__onPaymentResponse(result);
		} catch (error: unknown) {
			if (error instanceof BpError)
				this.__onPaymentError(error);
			else
				throw error;
		}
	}

	private __onPaymentResponse(transaction: Transaction): void {
		this._isSubmitting$.next(false);

		this.__hostNotifier.requestPaymentSuccess(this.__notificationContext);

		this._session.isRegularLikeCheckout && this._appService.removeAlertBeforeUnload();

		this._appService.transaction = transaction;

		if (this._session.isPayWithCheckout && transaction.isPendingOrInProcess)
			this._paymentOptionInstancesManager.markAsPending();

		if (transaction.isApproved) {
			if (this._session.isPayWithCheckout)
				this._paymentOptionInstancesManager.successContinue(transaction);
			else
				this._appService.navigate([ '/status/card-success' ]);

		} else if (transaction.html) {
			if (this._appService.isEmbedded || transaction.html.includes(HAS_SELF_SUBMIT_FORM_KEY))
				this._appService.navigateTo3dSecure(transaction.html, this._provider === 'none' ? '' : this._provider);
			else {
				TelemetryService.log('Redirected to the trx html content');

				this._appService.navigatePageTo3dParty(transaction.html);
			}
		} else if (transaction.isCreditCardTrxInProcess) {
			this._isSubmitting$.next(true);

			setTimeout(() => void this.__checkPaymentStatus(transaction), 10 * 1000);
		} else {
			this._appService.navigate([
				transaction.isPending ? '/status/pending' : '/error',
			]);
		}
	}

	private __onPaymentError(error: BpError<IApiTransaction>): void {
		this._session.isRegularLikeCheckout && this._appService.removeAlertBeforeUnload();

		let transaction: Transaction | undefined;

		if (error.payload && 'status' in error.payload)
			transaction = this._appService.transaction = new Transaction(error.payload);

		if (transaction?.isTryAgain && (this._paymentMethod.process_as_credit_card || this._provider === 'none'))
			void this.__makePayment();
		else {
			this._appService.setError(error);

			this._isSubmitting$.next(false);

			this.__hostNotifier.requestPaymentError(this.__notificationContext);

			transaction && this.__whenDeclinedDueToInvalidCreditCardShowError(transaction);

			if (error.status === ResponseStatusCode.BadRequest) {
				let hasFieldError = false;

				for (const { message, field, type } of error.messages.filter(errorMessage => errorMessage.field)) {
					const control = this.__getControlForErrorField(<keyof IRequestCardDeposit>field);

					if (control) {
						control.setErrors({
							server: this.__translateService.instant(
								type === 'card_bin_is_not_allowed'
									? cardBinIsNotAllowedErrorMessage
									: message,
							),
						});

						control.markAsTouched();

						hasFieldError = true;
					}
				}

				if (!hasFieldError)
					this._globalError = 'The card is invalid, try a different card.';
			} else if (this._session.isPayWithCheckout) {
				this._paymentOptionInstancesManager.handleDeclinedTransaction();

				this._globalError = PAYWITH_PAYMENT_FAILED_MSG;
			} else {
				this._appService.navigate([
					transaction && error.status === ResponseStatusCode.TransactionDeclined
						? '/status/card-declined'
						: 'error',
				]);
			}
		}
	}

	private __whenDeclinedDueToInvalidCreditCardShowError(transaction: TransactionInfo): void {
		if (!transaction.isDeclinedDueToInvalidCard)
			return;

		this._globalError = 'The card is invalid, try a different card.';
	}

	private __buildPaymentRequestBody(): IRequestCardDeposit {
		const { month, year } = PaymentCardsUtils.parseExpireDateString(this._controls.cardExpiryDate!.value);

		return <IRequestCardDeposit>omitBy(<IRequestCardDeposit>{
			type: 'credit_card',
			address1: this.__getAddress(),
			currency: this._paymentMethod.hide_amount ? null : this._currency!.code,
			first_name: this.__getControlValue('firstName'),
			last_name: this.__getControlValue('lastName'),
			city: this.__getControlValue('city'),
			zip_code: this.__getControlValue('zipCode'),
			state: this.__getControlValue('stateCode'),
			country: this._session.countryCode.value,
			phone: this.__getPaymentRequestPhone(),
			phone_country_dial_code: this.__getControlValue('countryDialCode'),
			email: this.__getControlValue('email'),
			amount: this._paymentMethod.hide_amount ? null : this._getAmount(),

			card_holder_name: this.__getControlValue('cardHolderName')!.trim(),
			credit_card_number: this.__getControlValue('cardNumber')!.replace(/\s/ug, ''),
			expire_month: month,
			expire_year: year,
			cvv2: this.__getControlValue('cardCvv')!,

			gender: this.__getControlValue('gender'),
			birth_date: this.__getControlValue('birthDate')?.unix(),
			personal_id: this.__getControlValue('personalId'),
			personal_id_type: this.__getControlValue('personalIdType')?.name,
			pin: this.__getControlValue('pin'),

			affiliate_id: this._session.affiliateId,
			tracking_id: this._session.trackingId,
			ip: this._session.ip,
			amount_lock: this._session.amountLock,
			currency_lock: this._session.currencyLock,

			provider: this._provider,
			payment_method_type: this._paymentMethod.typeInSnakeCase,
			additional_data: this._appService.browserDetails,
			browser_details: this._appService.browserDetails,
			save_card: !!this.__getControlValue('saveCard'),
			credit_card_token: this.__getControlValue('cardToken'),

			installment_option_id: this.__getControlValue('installmentOptionId'),
			ddc: this._pspsFingerprints,
			payment_method_instance_id: this._paymentOptionInstancesManager.activeInstance?.id,
			payment_id: uuid(),
			...this._paymentMethod.fields
				? transform(
					this._paymentMethod.fields,
					(config, fieldMetadata) => (config[fieldMetadata.name] = this.__getControlValue(fieldMetadata.name)),
				)
				: {},
		}, isNil);
	}

	private __getPaymentRequestPhone(): string | undefined {
		const phone = this.__getControlValue('phone');
		const dialCode = this.__getControlValue('countryDialCode');

		return phone && dialCode && (phone.startsWith(dialCode) ? phone : `${ dialCode }${ phone }`);
	}

	private __getControlValue<TKey extends keyof IPaymentForm>(key: TKey): IPaymentForm[TKey] | undefined {
		return this._controls[key]?.value;
	}

	private __mapRequestPropertyNameToControlName(field: keyof IRequestCardDeposit): keyof IPaymentForm {
		let controlName: keyof IPaymentForm;

		switch (field) {
			case 'address1':
				controlName = 'address';
				break;

			case 'first_name':

			case 'last_name':

			case 'card_holder_name':
				controlName = 'cardHolderName';
				break;

			case 'credit_card_number':
				controlName = 'cardNumber';
				break;

			case 'expire_year':

			case 'expire_month':
				controlName = 'cardExpiryDate';
				break;

			case 'cvv2':
				controlName = 'cardCvv';
				break;

			case 'state':
				controlName = 'stateCode';
				break;

			default:
				controlName = <keyof IPaymentForm>camelCase(<string>field);
		}

		return controlName;
	}

	private __getControlForErrorField(field: keyof IRequestCardDeposit): FormControl | null {
		return this._controls[this.__mapRequestPropertyNameToControlName(field)] ?? null;
	}

	// #endregion submitting

	protected _isAdditional(fieldName: typeof additionalInputFields[number]): boolean {
		return this._paymentMethod.additional_required_fields.includes(fieldName);
	}

	private __isHiddenField(fieldName: keyof IPaymentForm): boolean {
		return this._paymentMethod.skip_required_fields.includes(<string>fieldName);
	}

	private __getAddress(): string | undefined {
		const address = this.__getControlValue('address');

		return this._session.isEquitiJordanAndUAE
			? address?.slice(0, 50)
			: address;
	}

	private __backendCardBinValidator(): AsyncValidatorFn {
		// eslint-disable-next-line @typescript-eslint/promise-function-async
		return ({ value }) => isEmpty(this._form) || this._controls.cardToken!.value || (<string>value).length < 6 || hasMaskedChars(<string>value)
			? Promise.resolve(null)
			: this.__paymentsApiService
				.validateCardBin(
					(<string>value).replace(/\s/ug, '').slice(0, 10),
					this._currency?.code ?? this._paymentMethod.currencyCode,
				)
				.pipe(
					map(() => null),
					catchError((error: unknown) => of(error instanceof BpError && error.message
						? {
							unsupportedCardNumber: {
								brands: error.message,
							},
						}
						: { [this.__translateService.instant(cardBinIsNotAllowedErrorMessage)]: null })),
				);
	}

}

interface IPaymentForm {
	firstName?: string;
	lastName?: string;
	address?: string;
	city?: string;
	state?: string;
	stateCode?: string;
	zipCode?: string;
	countryDialCode?: string;
	phone?: string;
	email?: string;
	cardHolderName: string;
	cardNumber: string;
	cardExpiryDate: string;
	cardToken: string | null;
	cardCvv: string;
	amount: number;
	saveCard: boolean;
	birthDate?: m.Moment;
	gender?: IRequestCardDeposit['gender'];
	personalId?: string;
	personalIdType?: IPersonalIdType;
	installmentOptionId?: string;
	pin?: string;
	[key: string]: unknown;
}
